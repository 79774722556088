import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/scss/base.scss";
import Home from "./pages/home";
import { Navigation } from "./components";
import Blog from "./pages/blog";
import AboutUs from "./pages/about-us";
import Recipies from "./pages/recipies";
import Recipie from "./pages/recipie";
import Hops from "./pages/hops";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route>
            <Route path="/" element={<Home />} />
            <Route path="/blog/:blogTitle/:id/" element={<Blog />} />
            <Route path="/recipies-list" element={<Recipies />} />
            <Route path="/recipie/:recipieId/" element={<Recipie />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/hops" element={<Hops />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
