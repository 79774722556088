import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

function Home() {
  const [prevBlogs, setPrevBlogs] = useState();
  const [featured, setFeatured] = useState();

  useEffect(() => {
    axios({
      url: "https://boiling-harbor-69600.herokuapp.com/home",
      method: "GET",
    })
      .then((response) => {
        setFeatured(response.data[0]);
        setPrevBlogs(response.data[1].prevBlogs);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      {prevBlogs && featured && (
        <div className="home">
          <Link
            className="home-hero"
            to={`/blog/${featured.title}/${featured.id}`}
          >
            <div
              style={{
                backgroundImage: `url(${featured.image}) `,
              }}
              className="home-hero-cta-featured"
            >
              <h1 className="home-hero-cta-title">{featured.title}</h1>

              <Button className="home-hero-cta-btn" variant="warning">
                Read More
              </Button>
            </div>
          </Link>
          <div className="home-wrap">
            {prevBlogs.slice(1, prevBlogs.length).map((data, key) => (
              <Link
                className="home-link"
                to={`/blog/${data.blogParagraphRich.json.content[0].content[0].value}/${data.sys.id}`}
                key={key}
              >
                <div
                  style={{
                    backgroundImage: `url(${data.blogAssetsCollection.items[0].url})`,
                  }}
                  className="home-container"
                >
                  {data.blogParagraphRich.json.content[0].content[0].value}
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
