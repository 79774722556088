const content = [
  {
    styles: [
      "All",
      "American Amber Ale ",
      "American Barleywine ",
      "American Brown Ale ",
      "American IPA ",
      "American Pale Ale ",
      "American Stout ",
      "American Wheat or Rye Beer ",
      "Baltic Porter ",
      "Belgian Blond Ale ",
      "Belgian Dark Strong Ale ",
      "Belgian Dubbel ",
      "Belgian Golden Strong Ale ",
      "Belgian Pale Ale ",
      "Belgian Specialty Ale ",
      "Belgian Tripel ",
      "Berliner Weisse ",
      "Bière de Garde ",
      "Blonde Ale ",
      "Bohemian Pilsener ",
      "Braggot ",
      "Brown Porter ",
      "California Common Beer ",
      "Christmas/Winter Specialty Spiced Beer ",
      "Classic American Pilsner ",
      "Classic Rauchbier ",
      "Common Cider ",
      "Common Perry ",
      "Cream Ale ",
      "Cyser ",
      "Dark American Lager ",
      "Doppelbock ",
      "Dortmunder Export ",
      "Dry Mead ",
      "Dry Stout ",
      "Duesseldorf Altbier",
      "Dunkelweizen ",
      "Eisbock ",
      "English Barleywine ",
      "English Cider ",
      "English IPA ",
      "Extra Special/Strong Bitter (English Pale Ale) ",
      "Flanders Brown Ale/Oud Bruin ",
      "Flanders Red Ale ",
      "Foreign Extra Stout ",
      "Fruit Beer ",
      "Fruit Cider ",
      "Fruit Lambic ",
      "German Pilsner (Pils) ",
      "Imperial IPA ",
      "Irish Red Ale ",
      "KÃlsch ",
      "Lite American Lager ",
      "Maibock/Helles Bock ",
      "Metheglin ",
      "Mild ",
      "Munich Dunkel ",
      "Munich Helles ",
      "New England Cider ",
      "Northern English Brown Ale ",
      "Northern German Altbier ",
      "Oatmeal Stout ",
      "Oktoberfest/MÃ¤rzen ",
      "Old Ale ",
      "Open Category Mead ",
      "Other Fruit Melomel ",
      "Other Smoked Beer ",
      "Other Specialty Cider/Perry ",
      "Premium American Lager ",
      "Pyment ",
      "Robust Porter ",
      "Roggenbier (German Rye Beer) ",
      "Russian Imperial Stout ",
      "Saison ",
      "Schwarzbier (Black Beer) ",
      "Scottish Export 80/- ",
      "Scottish Heavy 70/- ",
      "Scottish Light 60/- ",
      "Semi-sweet Mead ",
      "Southern English Brown ",
      "Special/Best/Premium Bitter ",
      "Specialty Beer ",
      "Spice, Herb, or Vegetable Beer ",
      "Standard American Lager ",
      "Standard/Ordinary Bitter ",
      "Straight (Unblended) Lambic ",
      "Strong Scotch Ale ",
      "Sweet Stout ",
      "Traditional Bock ",
      "Vienna Lager ",
      "Weizen/Weissbier ",
      "Weizenbock ",
      "Witbier ",
      "Wood-Aged Beer ",
    ],
  },
];

export default content;
