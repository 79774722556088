import hops from "../assets/data-content/hops.json";

function Hops() {
  return (
    <div className="hops-container">
      <table id="hops-list">
        <thead>
          <tr>
            <th>Hop</th>
            <th>Apha Content</th>
            <th>Description</th>
            <th>Substitute</th>
          </tr>
        </thead>
        <tbody>
          {hops.map((hop) => {
            return (
              <tr>
                <td>{hop.Hop}</td>
                <td>{hop.Alpha}</td>
                <td>{hop.Description}</td>
                <td>{hop.Substitute}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Hops;
