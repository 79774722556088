import React, { useState } from "react";
import { Link } from "react-router-dom";
import menu from "../assets/images/menu.svg";

const navLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Brewing Recipies",
    path: "/recipies-list",
  },
  {
    title: "Hops",
    path: "/hops",
  },
  {
    title: "About Us",
    path: "/about-us",
  },
];

function Navigation(props) {
  const [menuActive, setMenuActive] = useState(false);

  return (
    <nav
      className={`site-navigation ${menuActive && "active"}`}
      role="navigation"
    >
      <Link to="/">
        <span className="menu-title">Brewer Cave</span>
      </Link>
      <div className="menu-content-container">
        <ul>
          {navLinks.map((link, index) => (
            <li key={index}>
              <Link to={link.path}>{link.title}</Link>
            </li>
          ))}
        </ul>
      </div>
      <i
        className="icon ionicons ion-ios-menu"
        onClick={(ev) => setMenuActive(!menuActive)}
      >
        <img src={menu} alt="menu icon" />
      </i>
    </nav>
  );
}

export default Navigation;
