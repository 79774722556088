import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";
import axios from "axios";

function Blog() {
  const { id } = useParams();
  const [richData, setRichData] = useState();
  const [image, setImage] = useState();

  useEffect(() => {
    axios({
      url: `https://boiling-harbor-69600.herokuapp.com/content/${id}`,
      method: "get",
    })
      .then((response) => {
        setRichData(response.data.content);
        setImage(response.data.image);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  console.log(richData);

  return (
    <div className="blog-container">
      {richData && image && (
        <section>
          <img src={image} alt="blog" className="blog-image" />
          {richData.map((data, key) => {
            if (data.nodeType === "heading-1") {
              return (
                <h1 className="blog-title__h1" key={key}>
                  {data.content[0].value}
                </h1>
              );
            } else if (data.nodeType === "heading-2") {
              return (
                <h2 className="blog-title__h2" key={key}>
                  {data.content[0].value}
                </h2>
              );
            } else if (data.nodeType === "heading-3") {
              return (
                <h3 className="blog-title__h3" key={key}>
                  {data.content[0].value}
                </h3>
              );
            } else if (data.nodeType === "heading-4") {
              return (
                <h4 className="blog-title__h4" key={key}>
                  {data.content[0].value}
                </h4>
              );
              // } else if (data.nodeType === "paragraph") {
              //   return (
              //     <p className="blog-content" key={key}>
              //       {data.content[0].value}
              //     </p>
              //   );
            } else if (data.nodeType === "paragraph") {
              return data.content.map((paragraph, outterKey) => {
                if (paragraph.nodeType === "text") {
                  return (
                    <p className="blog-content" key={outterKey}>
                      {paragraph.value}
                    </p>
                  );
                } else if (paragraph.nodeType === "hyperlink") {
                  return (
                    <a
                      className="blog-link"
                      href={paragraph.data.uri}
                      key={outterKey}
                    >
                      {paragraph.content[0].value}
                    </a>
                  );
                }
                return null;
              });
            } else if (data.nodeType === "ordered-list") {
              return (
                <ol key={key}>
                  {data.content.map((list) => {
                    return list.content.map((listItem, outterKey) => {
                      return listItem.content.slice(0, 2).map((listItem) => {
                        if (listItem.nodeType === "text") {
                          return (
                            <React.Fragment key={outterKey}>
                              {listItem.value && (
                                <li className="blog-list">{listItem.value}</li>
                              )}
                            </React.Fragment>
                          );
                        } else if (listItem.nodeType === "hyperlink") {
                          return (
                            <li className="blog-list" key={outterKey}>
                              <a className="blog-link" href={listItem.data.uri}>
                                {listItem.content[0].value}
                              </a>
                            </li>
                          );
                        }
                        return null;
                      });
                    });
                  })}
                </ol>
              );
            }
            return null;
          })}
        </section>
      )}
    </div>
  );
}

export default Blog;
