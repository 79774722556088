import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import head from "../assets/images/head.png";
import axios from "axios";

function Recipie() {
  const { recipieId } = useParams();
  const [recipie, setRecipie] = useState();

  useEffect(() => {
    axios({
      url: `https://boiling-harbor-69600.herokuapp.com/recipie/${recipieId}`,
      method: "get",
    })
      .then((response) => {
        setRecipie(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [recipieId]);

  return (
    <div>
      {recipie && (
        <section>
          <img src={head} alt="head" className="recipie-img" />
          <div className="recipie-container">
            {recipie.title && (
              <h1 className="recipie-title">{recipie.title}</h1>
            )}
            {recipie.style && (
              <h2 className="recipie-style">{recipie.style}</h2>
            )}
            {recipie.volume && (
              <h3 className="recipie-volume">{recipie.volume}</h3>
            )}
            {recipie.description && (
              <>
                <p className="recipie-content">{recipie.description}</p>
              </>
            )}
            {recipie.ingredients && (
              <>
                <h2 className="recipie-ingredients">Ingredients</h2>
                {recipie.ingredients.map((ingredient, key) => (
                  <ul key={key}>
                    <li>{ingredient}</li>
                  </ul>
                ))}
              </>
            )}
            {recipie.recipeInstructions && (
              <>
                <h2 className="recipie-instructions">Instructions</h2>
                <p>{recipie.recipeInstructions}</p>
              </>
            )}
          </div>
        </section>
      )}
    </div>
  );
}

export default Recipie;
