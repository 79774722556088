import relaxBeer from "../assets/images/relaxDrinkDarken.jpg";

function AboutUs() {
  return (
    <div
      className="about-wrapper"
      style={{
        backgroundImage: `url(${relaxBeer})`,
      }}
    >
      <h2>About Us</h2>
      <h3>Share Brewing Experiences</h3>
      <p>
        Brewing your own beer is a fulfilling and enjoyable experience for many
        reasons, and these reasons I want to share with the world. One of the
        main reasons is the ability to customize the final product to suit your
        specific tastes. Whether you prefer a hoppy IPA or a malty stout,
        brewing your own beer allows you to experiment with different
        ingredients and techniques to create a brew that is uniquely yours. You
        can also share your creations with friends and family, and experience
        the satisfaction that comes from seeing them enjoy something that you
        have made with your own hands. This allows you to achieve
        self-sufficiency and independence from commercial breweries and is great
        way to realize the satisfaction that comes from the fruits of your labor
        and the sense of accomplishment that comes from creating something from
        scratch.
      </p>
      <h3>Brewing Beer WIth Minimalistic Approach</h3>
      <p>
        I've got a secret to share with you. Brewing your own beer doesn't have
        to be complicated or expensive. In fact, I like to keep things simple
        and use relatively inexpensive equipment. Sure, you can take this hobby
        to the furthest extent your pocket will allow, but you don't have to
        break the bank to brew a delicious batch of suds. Trust me, the
        satisfaction of sipping on a cold one that you made yourself is worth
        it. So, don't be intimidated, give brewing a try and see for yourself.
      </p>
      <h3>Recipies</h3>
      <p>
        I'm always experimenting with new recipes and I want to share my
        findings with you. I'll be sharing the recipes I've tried and giving you
        an honest review of each one. My goal is to provide you with enough
        information so you can decide if it's a recipe worth trying or if it's
        not your cup of tea. So, whether you're a seasoned brewer or just
        starting out, come along for the ride and let's see what tasty brews we
        can come up with together.{" "}
      </p>
      <h3>Equipment and Ingredients Sourcing</h3>
      <p>
        Now, before you get started, there are a few things you're going to
        need. That's why I've decided to give you the rundown on the equipment
        and ingredients you'll need to get started. I'll give you my honest
        reviews on everything so you can avoid the mistakes that others have
        made. Trust me, brewing beer is a lot more fun when you have the right
        tools and ingredients at your disposal. So, let's get started and make
        some delicious beer together!
      </p>
    </div>
  );
}

export default AboutUs;
