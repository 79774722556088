import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import tapOptions from "../assets/images/tapsOptions.jpg";
import dataContent from "../assets/data-content/content";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";

function Recipies() {
  const [recipies, setRecipies] = useState([]);
  const [filter, setFilter] = useState("All");

  const [startingPage, setStartingPage] = useState(0);
  const [endingPage, setEndingPage] = useState(25);
  const pageSize = 25;

  const handleBackClick = () => {
    if (startingPage - pageSize >= 0) {
      setStartingPage(startingPage - pageSize);
      setEndingPage(endingPage - pageSize);
    }
  };

  const handleForwardClick = () => {
    if (endingPage + pageSize <= recipies.length) {
      setStartingPage(startingPage + pageSize);
      setEndingPage(endingPage + pageSize);
    }
  };

  const styles = dataContent[0].styles;
  const currentPage = recipies.slice(startingPage, endingPage);

  useEffect(() => {
    axios({
      url: `https://boiling-harbor-69600.herokuapp.com/recipies/${filter}/`,
      mehtod: "get",
    })
      .then((response) => {
        const dataArr = response.data;
        const sorted = dataArr.sort((a, b) => a.style.localeCompare(b.style));
        setRecipies(sorted);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [filter]);

  return (
    <>
      {currentPage && (
        <div className="recipies-container">
          <img
            src={tapOptions}
            alt="tap options"
            className="recipies-beer-img"
          />
          <section className="recipies-section">
            <Dropdown className="d-inline mx-2">
              <Dropdown.Toggle id="dropdown-autoclose-true" className size="lg">
                Filter Styles
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {styles.map((style, key) => (
                  <Dropdown.Item
                    key={key}
                    onClick={() => {
                      setFilter(style);
                    }}
                  >
                    {style}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <table id="recipie-list">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Style</th>
                  <th>Type</th>
                  <th>Volume</th>
                </tr>
              </thead>
              {currentPage.map((recipie, key) => (
                <tbody key={key}>
                  <tr>
                    <td>
                      <Link
                        to={`/recipie/${recipie.uniqueid}`}
                        className="recipie-link"
                      >
                        {recipie.title}
                      </Link>
                    </td>
                    <td>{recipie.style}</td>
                    <td>{recipie.type}</td>
                    <td>{recipie.volume}</td>
                  </tr>
                </tbody>
              ))}
            </table>
            <div className="recipies-buttons">
              <Button
                className="recipies-buttons-btn"
                variant="warning"
                onClick={handleBackClick}
              >
                Back
              </Button>
              <Button
                className="recipies-buttons-btn"
                variant="warning"
                onClick={handleForwardClick}
              >
                Forward
              </Button>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default Recipies;
